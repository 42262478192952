<template>
	<v-layout>
		<Dialog :dialog="addressDialog" :dialogWidth="700">
			<template v-slot:title>
				<span v-if="doUpdate">Update</span>
				<span v-else>Add</span>
				<span>&nbsp;Address</span>
			</template>
			<template v-slot:body>
				<v-form
					ref="personForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="appendBillingAddress"
				>
					<v-row class="">
						<v-col md="3" class="my-auto py-0" v-if="false">
							<label for="display-name" class="btx-label mt-2 required">Address Type </label>
						</v-col>
						<v-col md="9" class="py-0" v-if="false">
							<v-radio-group v-model="billingAddress.address_type" row hide-details class="ma-0">
								<v-radio label="Billing Address" :value="1" class="mr-3" color="blue darken-4"></v-radio>
								<v-radio label="Shipping Address" :value="2" class="ma-0" color="blue darken-4"></v-radio>
							</v-radio-group>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="billing-label" class="btx-label mt-2 required"> Label </label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="billing-label"
								placeholder="Label"
								v-model="billingAddress.label"
								:rules="[vrules.required(billingAddress.label, 'Label')]"
								:class="{ required: !billingAddress.label }"
							></TextInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="display-name" class="btx-label mt-2 required"
								>Display Name
								<TooltipQuestion>
									<template v-slot:text
										>This name will be displayed on<br />the transactions you create for<br />this
										address</template
									>
								</TooltipQuestion>
							</label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextValidateInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Display Name"
								id="display-name"
								:rules="[
									vrules.required(billingAddress.display_name, 'Display Name'),
									vrules.minLength(billingAddress.display_name, 'display name', 2),
									vrules.maxLength(billingAddress.display_name, 'display name', 100),
								]"
								:class="{
									required: !billingAddress.display_name,
								}"
								:validationField="{
									url_type: 'customer',
									filter_type: 'address',
									field: 'display_name',
								}"
								:parent-id="parentId"
								:current-id="billingAddress.id"
								show-dropdown
								v-model="billingAddress.display_name"
							>
							</TextValidateInput>
							<TextInput
								v-if="false"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="display-name"
								:rules="[vrules.required(billingAddress.display_name, 'Display Name')]"
								:class="{
									required: !billingAddress.display_name,
								}"
								placeholder="Display Name"
								v-model="billingAddress.display_name"
							></TextInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="address-phone-no" class="btx-label mt-2 required">Phone</label>
						</v-col>
						<v-col md="9" class="py-0">
							<PhoneTemplate
								v-if="false"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-phone-no"
								placeholder="Phone No."
								v-model="billingAddress.phone_no"
								:rules="[
									vrules.required(billingAddress.phone_no, 'Phone No'),
									vrules.phoneNumber(billingAddress.phone_no, 'Phone No', 12),
								]"
								:class="{ required: !billingAddress.phone_no }"
							></PhoneTemplate>
							<PhoneInput
								required
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-phone-no"
								placeholder="Phone No."
								v-model="billingAddress.phone_no"
								:validationField="{
									url_type: 'customer',
									filter_type: 'address',
									field: 'phone_number',
								}"
								:parent-id="parentId"
								:current-id="billingAddress.id"
							></PhoneInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="address-email" class="btx-label mt-2 required">Email Address</label>
						</v-col>
						<v-col md="9" class="py-0">
							<EmailInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-email"
								placeholder="Email Address"
								v-model="billingAddress.email"
								:rules="[vrules.required(billingAddress.email, 'Email Address')]"
								:class="{ required: !billingAddress.email }"
								:validationField="{
									url_type: 'customer',
									filter_type: 'address',
									field: 'email',
								}"
								:parent-id="parentId"
								:current-id="billingAddress.id"
							></EmailInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="postal-code" class="btx-label mt-2 required">Postal Code</label>
						</v-col>
						<v-col md="9" class="py-0">
							<v-text-field
								outlined
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model.trim="billingAddress.address_postal_code"
								id="postal-code"
								class="mt-3"
								placeholder="Postal Code"
								type="number"
								v-mask="'######'"
								:rules="[
									vrules.required(billingAddress.address_postal_code, 'Postal Code'),
									vrules.phoneNumber(billingAddress.address_postal_code, 'postal code', 6),
								]"
								:class="{ required: !billingAddress.address_postal_code }"
							></v-text-field>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="address-line-1" class="btx-label mt-2 required"
								>Address Line 1
								<TooltipQuestion v-if="false">
									<template v-slot:text
										>This name will be displayed on<br />the transactions you create for<br />this
										address</template
									>
								</TooltipQuestion>
							</label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-line-1"
								placeholder="Address Line 1"
								v-model="billingAddress.address_line_1"
								:rules="[vrules.required(billingAddress.address_line_1, 'Address line 1')]"
								:class="{ required: !billingAddress.address_line_1 }"
							></TextInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-line-2"
								placeholder="Address Line 2"
								v-model="billingAddress.address_line_2"
							></TextInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="unit-number" class="btx-label mt-2">Unit No. </label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="unit-number"
								placeholder="Unit No."
								v-model="billingAddress.unit_number"
							>
							</TextInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="address-country" class="btx-label mt-2">Country </label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="address-country"
								placeholder="Country"
								v-model="billingAddress.address_country"
							>
							</TextInput>
						</v-col>
						<AddressTemplate v-if="false" v-model="address"> </AddressTemplate>
						<v-col md="3" class="mt-2 py-0" v-if="false">
							<label :for="`address-street-1`" class="btx-label mt-2 required">
								{{ billingAddress.address_type == 1 ? "Billing Address" : "Shipping Address" }}
							</label>
						</v-col>
						<v-col md="9" class="py-0" v-if="false">
							<TextAreaInput
								hide-details
								:id="`address-1`"
								:placeholder="billingAddress.address_type == 1 ? 'Billing Address' : 'Shipping Address'"
								v-model="billingAddress.address_line_1"
								:disabled="pageLoading"
								:rules="[vrules.required(billingAddress.address_line_1, 'Address')]"
								:loading="pageLoading"
								:class="{ required: !billingAddress.address_line_1 }"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="$emit('close', true)" depressed tile> Cancel </v-btn>
				<v-btn
					v-on:click="appendBillingAddress()"
					:disabled="!formValid"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
				>
					<span v-if="doUpdate">Update</span>
					<span v-else>Add Address</span>
				</v-btn>
			</template>
		</Dialog>
	</v-layout>
</template>
<script>
// import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
// import PostalCode from "@/view/components/PostalCode";
import TextInput from "@/view/components/TextInput";
import AddressTemplate from "@/view/components/AddressTemplate";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import TextAreaInput from "@/view/components/TextAreaInput";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { SET_ERROR } from "@/core/services/store/common.module";
import { cloneDeep, filter /* lowerCase */ } from "lodash";
import PhoneInput from "@/view/components/PhoneInput";
import TextValidateInput from "@/view/components/TextValidateInput";
/* import { saveData, getData, deleteData } from "@/core/services/local.service"; */

// import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		addressDialog: {
			type: Boolean,
			default: false,
		},
		isTenant: {
			type: Boolean,
			default: false,
		},
		doSave: {
			type: Boolean,
			default: false,
		},
		doUpdate: {
			type: Boolean,
			default: false,
		},
		customerId: {
			type: Number,
			default: 0,
		},
		parentId: {
			type: [Number, String],
			default: null,
		},
		updateId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formValid: true,
			// designationList: [],
			pageLoading: false,
			/* 	localStorageSaveKey: null, */
			billingAddressList: [],
			billingAddress: {
				id: null,
				uuid: null,
				address_type: 1,
				display_name: null,
				phone_no: null,
				email: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_postal_code: null,
				address_country: "Singapore",
				label: null,
				primary: false,
			},
		};
	},
	components: {
		Dialog,
		TextInput,
		// PostalCode,
		EmailInput,
		TextAreaInput,
		PhoneTemplate,
		TooltipQuestion,
		AddressTemplate,
		PhoneInput,
		TextValidateInput,
	},
	watch: {
		addressDialog() {
			this.initComponent();
			//this.initialize();
		},
		detail: {
			deep: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		initComponent() {
			const result = cloneDeep(this.value);
			this.billingAddressList = result;
			if (this.doUpdate) {
				this.$nextTick(() => {
					const billingaddress = this.billingAddressList[this.updateId];
					if (billingaddress) {
						this.$nextTick(() => {
							this.billingAddress = {
								id: billingaddress.id ?? null,
								uuid: billingaddress.uuid ?? null,
								address_type: billingaddress.address_type ?? null,
								display_name: billingaddress.display_name ?? null,
								phone_no: billingaddress.phone_no ?? null,
								email: billingaddress.email ?? null,
								address_line_1: billingaddress.address_line_1 ?? null,
								address_line_2: billingaddress.address_line_2 ?? null,
								unit_number: billingaddress.unit_number ?? null,
								label: billingaddress.label ?? "Primary",
								address_postal_code: billingaddress.address_postal_code ?? null,
								address_country: billingaddress.address_country ?? "Singapore",
								primary: billingaddress.primary ?? false,
							};
						});
					}
				});
			} else {
				// this.$nextTick(() => {
				// 	this.billingAddress = {
				// 		id: null,
				// 		uuid: null,
				// 		address_type: 1,
				// 		display_name: null,
				// 		phone_no: null,
				// 		email: null,
				// 		address_line_1: null,
				// 		address_line_2: null,
				// 		unit_number: null,
				// 		address_postal_code: null,
				// 		address_country: "Singapore",
				// 	};
				// });
			}
		},
		async appendBillingAddress() {
			if (!this.$refs.personForm.validate()) {
				return false;
			}

			let display_name = this.billingAddress.display_name;
			// let email = this.billingAddress.email;
			// let phone_no = this.billingAddress.phone_no;

			let _billing_addresses = this.billingAddressList;
			if (this.doUpdate) {
				const currentEmail = this.billingAddressList[this.updateId].email;
				_billing_addresses = this.billingAddressList.filter((row) => {
					return row.email != currentEmail;
				});
			}

			var displayNameObj = filter(_billing_addresses, function (billingaddress) {
				if (billingaddress.display_name == display_name) return billingaddress.display_name;
			});
			if (displayNameObj.length > 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Display Name ( ${display_name} ) already is used` },
				]);
				return false;
			}

			// var emailsObj = filter(_billing_addresses, function (billingaddress) {
			// 	if (lowerCase(billingaddress.email) == lowerCase(email)) {
			// 		return billingaddress.email;
			// 	}
			// });
			// if (emailsObj.length > 0) {
			// 	this.$store.commit(SET_ERROR, [{ model: true, message: `Email ${email} already is used` }]);
			// 	return false;
			// }

			// var phoneNumberObj = filter(_billing_addresses, function (billingaddress) {
			// 	if (billingaddress.phone_no == phone_no) return billingaddress.phone_no;
			// });
			// if (phoneNumberObj.length > 0) {
			// 	this.$store.commit(SET_ERROR, [{ model: true, message: `Phone ${phone_no} already is used` }]);
			// 	return false;
			// }
			if (this.doUpdate) {
				this.billingAddressList.splice(this.updateId, 1, {
					id: this.billingAddress.id,
					uuid: this.billingAddress.uuid,
					address_type: this.billingAddress.address_type,
					display_name: this.billingAddress.display_name,
					phone_no: this.billingAddress.phone_no,
					email: this.billingAddress.email,
					address_line_1: this.billingAddress.address_line_1,
					address_line_2: this.billingAddress.address_line_2,
					unit_number: this.billingAddress.unit_number,
					label: this.billingAddress.label,
					address_postal_code: this.billingAddress.address_postal_code,
					address_country: this.billingAddress.address_country,
					primary: this.billingAddress.primary,
				});
			} else {
				this.billingAddressList.push({
					id: null,
					uuid: null,
					address_type: this.billingAddress.address_type,
					display_name: this.billingAddress.display_name,
					phone_no: this.billingAddress.phone_no,
					email: this.billingAddress.email,
					address_line_1: this.billingAddress.address_line_1,
					address_line_2: this.billingAddress.address_line_2,
					unit_number: this.billingAddress.unit_number,
					label: this.billingAddress.label,
					address_postal_code: this.billingAddress.address_postal_code,
					address_country: this.billingAddress.address_country,
					primary: this.billingAddress.primary,
				});
			}

			this.$emit("input", this.billingAddressList);

			this.$emit("update:billing_address", this.billingAddress);

			this.$nextTick(() => {
				this.billingAddress = {
					id: null,
					uuid: null,
					address_type: 1,
					display_name: null,
					phone_no: null,
					email: null,
					address_line_1: null,
					address_line_2: null,
					unit_number: null,
					label: null,
					address_postal_code: null,
					primary: false,
					address_country: "Singapore",
				};
			});
			// clearing local storage data
			/* 	deleteData(this.localStorageSaveKey); */
			this.$emit("close", true);
		},
		/* saveToLocal() {
			const create_billing_address = {
				display_name: this.billingAddress.display_name,
				phone_no: this.billingAddress.phone_no,
				email: this.billingAddress.email,
				address_line_1: this.billingAddress.address_line_1,
				address_line_2: this.billingAddress.address_line_2,
				unit_number: this.billingAddress.unit_number,
				address_postal_code: this.billingAddress.address_postal_code,
			};

			saveData(this.localStorageSaveKey, create_billing_address);
		}, */
		/* loadLocalData() {
			console.log(this.$route.name, this.$route.name === "customer-create");
			if (this.$route.name === "customer-create") {
				this.localStorageSaveKey = "create_customer_billing_address";
			}
			saveData(this.localStorageSaveKey, create_billing_address);
		}, */
		/* loadLocalData() {
			let saveBillingAddress = getData(this.localStorageSaveKey);

			if (saveBillingAddress) {
				this.billingAddress = {
					display_name: saveBillingAddress.display_name,
					phone_no: saveBillingAddress.phone_no,
					email: saveBillingAddress.email,
					address_line_1: saveBillingAddress.address_line_1,
					address_line_2: saveBillingAddress.address_line_2,
					unit_number: saveBillingAddress.unit_number,
					address_postal_code: saveBillingAddress.address_postal_code,
				};
			}
		}, */
	},
	mounted() {
		this.initComponent();
	},
	computed: {
		// ...mapGetters(["localDB"]),
	},
};
</script>
