<template>
	<v-row class="mt-3">
		<v-col md="12" class="text-right pt-0">
			<v-btn
				v-on:click="createBillingAddress()"
				class="white--text mr-2"
				depressed
				color="blue darken-4"
				tile
			>
				Add Address
			</v-btn>
		</v-col>
		<v-col md="12">
			<v-simple-table class="bt-table">
				<thead>
					<tr>
						<th class="simple-table-th" width="50">Address type</th>
						<th class="simple-table-th">Label</th>
						<th class="simple-table-th">Display Name</th>
						<th class="simple-table-th">Phone</th>
						<th class="simple-table-th">Email Address</th>
						<th class="simple-table-th" width="350">Address</th>
						<th class="simple-table-th"></th>
					</tr>
				</thead>
				<tbody v-if="billing_addressList.length">
					<tr v-for="(row, index) in billing_addressList" :key="index">
						<td class="cursor-default simple-table-td">
							<div class="d-flex align-items-center">
								<v-checkbox
									hide-details
									color="blue darken-4"
									class="ma-0 pa-0 disable-color-primary"
									v-model="row.primary"
									:disabled="isDisable(row.primary)"
									v-on:change="makePrimary(index)"
								></v-checkbox>
								<v-chip class="mx-2 px-2" label x-small color="green" v-if="row.primary">
									<span class="white--text fw-500">Billing Address</span>
								</v-chip>
								<v-chip class="mx-2 px-2" label x-small color="blue lighten-4" v-else>
									<span class="blue--text text--darken-4 fw-500">Serivice Location</span>
								</v-chip>
							</div>
						</td>
						<td class="cursor-default simple-table-td">
							<ShowValue :object="row" object-key="label" label="label"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<ShowValue :object="row" object-key="display_name" label="display name"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<ShowValue :object="row" object-key="phone_no" label="phone number"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<ShowValue :object="row" object-key="email" label="email"></ShowValue>
						</td>
						<td class="cursor-default simple-table-td">
							<template v-if="row.address_line_1"> {{ row.address_line_1 }}, </template>
							<template v-if="row.address_line_2">
								{{ row.address_line_2 && row.address_line_2 }},
							</template>
							<template v-if="row.unit_number"> {{ row.unit_number }}, </template>
							<template v-if="row.address_country"> {{ row.address_country }} &nbsp; </template>
							<template v-if="row.address_postal_code">
								{{ row.address_postal_code }}
							</template>
						</td>
						<td class="simple-table-td">
							<v-btn
								class="mx-2"
								color="blue darken-4"
								dark
								fab
								x-small
								v-on:click="editBillingAddress(index, row)"
							>
								<v-icon small dark> mdi-pencil</v-icon>
							</v-btn>
							<template v-if="!row.primary">
								<v-btn
									class="mx-2"
									color="red lighten-1"
									dark
									fab
									x-small
									v-on:click="removeBillingAddress(index, row)"
								>
									<v-icon small dark> mdi-trash-can-outline</v-icon>
								</v-btn>
							</template>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="8">
							<p class="my-4 row-not-found text-center font-weight-500 font-size-16">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Billing Address at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</v-col>
		<CreateBillingAddress
			v-if="dialog"
			v-model="billing_addressList"
			:parent-id="parentId"
			:address-dialog="dialog"
			v-on:close="dialog = false"
			:do-update="updateDialog"
			:updateId="editIndex"
			v-on:update:billing_address="$emit('saveToLocal', billing_addressList)"
		></CreateBillingAddress>
	</v-row>
</template>

<script>
import CreateBillingAddress from "@/view/components/CreateBillingAddress";
import ShowValue from "@/view/components/ShowValue";
import ApiService from "@/core/services/api.service";
import { cloneDeep, map } from "lodash";

export default {
	name: "customer-billing-address",
	title: "Billing Address",
	props: {
		detail: {
			type: Object,
			default: function () {
				return {};
			},
		},
		customer: {
			type: Object,
			default: function () {
				return {};
			},
		},
		defaultBillingAddress: {
			type: Array,
			default: function () {
				return [];
			},
		},
		isDialog: {
			type: Boolean,
			default: false,
		},
		person: {
			type: Number,
			default: 0,
		},
		parentId: {
			type: [Number, String],
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		updateBillingAddress: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dialog: false,
			updateDialog: false,
			billing_address: {
				id: null,
				uuid: null,
				display_name: null,
				phone_no: null,
				email: null,
				unit_number: null,
				label: null,
				address_line_1: null,
				address_line_2: null,
				address_postal_code: null,
				address_country: "Singapore",
				address_type: 1,
				primary: false,
				default: 0,
			},
			editIndex: -1,
			billing_addressList: [],
			updateContactPerson: {},
		};
	},
	watch: {
		billing_addressList: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					let result = cloneDeep(param);
					this.$emit("saveBillingAddress", result);
				}
			},
		},
		updateBillingAddress: {
			handler() {
				this.makeDefaultEntry();
			},
		},
		detail: {
			deep: true,
			immediate: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		makePrimary(index) {
			const result = cloneDeep(this.billing_addressList);
			const _elem = map(result, (element) => {
				return { ...element, primary: false };
			});
			_elem[index].primary = true;
			this.billing_addressList = _elem;
		},
		makeDefaultEntry() {
			const _this = this;
			try {
				if (_this.updateBillingAddress) {
					_this.billing_addressList = _this.defaultBillingAddress;
				} else {
					let firstEntry = {
						id: null,
						uuid: null,
						display_name: _this.detail.display_name ? _this.detail.display_name : null,
						phone_no: _this.detail.phone_no ? _this.detail.phone_no : null,
						email: _this.detail.email ? _this.detail.email : null,
						unit_number: _this.detail.unit_number ? _this.detail.unit_number : null,
						label: _this.detail.label ? _this.detail.label : "Primary",
						address_line_1: _this.detail.address_line_1 ? _this.detail.address_line_1 : null,
						address_line_2: _this.detail.address_line_2 ? _this.detail.address_line_2 : null,
						address_postal_code: _this.detail.address_postal_code
							? _this.detail.address_postal_code
							: null,
						address_country: _this.detail.address_country ? _this.detail.address_country : null,
						primary: true,
						default: _this.detail.default ? _this.detail.default : 0,
					};
					this.$nextTick(() => {
						_this.billing_addressList.splice(0, 1, firstEntry);
					});
					if (_this.defaultBillingAddress.length) {
						_this.billing_addressList = _this.defaultBillingAddress;
					}
				}
			} catch (error) {
				_this.billing_addressList.splice(0, 1);
				_this.logError(error);
			}
		},
		removeBillingAddress(index, data) {
			this.billing_addressList.splice(index, 1);
			if (data.uuid && this.typeUuid) {
				const endpoint = `${this.type}/${this.typeUuid}/delete-contact-address/${data.uuid}/address`;
				ApiService.patch(endpoint)
					.then(() => {
						// this.$store.dispatch(SET_LOCAL_DB);
					})
					.catch((error) => {
						this.logError(error);
					});
			}
			this.$emit("saveToLocal", this.billing_addressList);
			if (data.primary == 1) {
				this.billing_addressList[0].primary = 1;
			}
		},
		editBillingAddress(index) {
			(this.updateDialog = true), (this.editIndex = index);
			this.dialog = true;
		},
		createBillingAddress() {
			(this.updateDialog = false), (this.editIndex = -1);
			this.dialog = true;
		},
	},
	computed: {
		isDisable: () => {
			return function (x) {
				if (x) {
					return true;
				}
				if (this.billing_addressList.length === 1) {
					this.billing_addressList[0].primary = true;
					return true;
				}
				return false;
			};
		},
	},
	components: {
		CreateBillingAddress,
		ShowValue,
	},
	mounted() {
		/* if (this.isDialog) {
			if (this.contactPersons.length <= 0) {
				this.contactPersons.push({
					id: null,
					title: "mr",
					menu: false,
					first_name: null,
					last_name: null,
					display_name: null,
					primary_email: null,
					primary_phone: null,
					emails: [],
					will_notified: 1,
					position: null,
					designation: null,
					primary: 0,
					default: 0,
				});
			}
		} */
		this.makeDefaultEntry();
		if (this.person > 0) {
			this.contactPersons = [this.getPersonById(this.customer, this.person)];
		}
	},
};
</script>
