import { render, staticRenderFns } from "./Create-Customer.vue?vue&type=template&id=00c52fa3&"
import script from "./Create-Customer.vue?vue&type=script&lang=js&"
export * from "./Create-Customer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports